<template>
    <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>
                  Syllabuses
                </h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link> \ <router-link to="/category">Categories </router-link> \ <router-link to="/instrument">Families </router-link> \ Syllabuses
                </div>
              </div>
              <div class="breadcrumb-right">
                <div class="card-toolbar">
                  <v-btn
                      @click="createSyllabus()"
                      class="btn btn-primary font-weight-bolder font-size-sm"
                      style="color: #fff;">
                    <i class="fa fa-plus"></i>
                    Add syllabus
                  </v-btn>
                </div>
              </div>
            </div>
          </div>

          <div class="card-body">
            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
              <div class="row">
                <v-col cols="12" sm="6" md="2">
                  <v-text-field 
                    label="Syllabus"
                    v-model="search.name" 
                    outlined 
                    dense
                    v-on:keyup.enter="searchSyllabuses()"
                    @input="search.name = $event !== null ? $event : ''" 
                    clearable
                  ></v-text-field>
                </v-col>
                

                <v-col class="d-flex" cols="12" sm="6" md="3">
                  <v-select 
                    :items="search_categories" 
                    v-model="search.category_id" 
                    label="Category" 
                    item-text="name" 
                    item-value="id" 
                    clearable
                    @change="showInstrument"
                    v-on:keyup.enter="searchSyllabuses()"
                    @input="search.category_id = $event !== null ? $event : ''" 
                    outlined 
                    dense
                  ></v-select>
                </v-col>

                <v-col class="d-flex" cols="12" sm="6" md="3">
                  <v-select 
                    :items="search_instruments" 
                    v-model="search.instrument_id" 
                    label="Instrument family"
                    item-text="name" 
                    item-value="id" 
                    outlined 
                    :loading="loading_instrument"
                    v-on:keyup.enter="searchSyllabuses()"
                    clearable
                    @input="search.instrument_id = $event !== null ? $event : ''"
                    dense
                  ></v-select>
                </v-col>

                <v-col class="d-flex" cols="12" sm="6" md="2">
                  <v-select
                      :items="search_statuses"
                      v-model="search.status"
                      label="Status"
                      item-text="name"
                      item-value="value"
                      outlined
                      v-on:keyup.enter="searchSyllabuses()"
                      clearable
                      dense
                  ></v-select>
                </v-col>

                <v-col cols="12" sm="6" md="2">
                  <v-btn 
                    @click="searchSyllabuses()" 
                    class="btn btn-primary w-35 float-right"
                    style="color: #fff"
                    :loading="loading"
                  >
                    <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                    Search
                  </v-btn>
                </v-col>
              </div>

            </div>  
            
            <!-- table starts -->
            <div class="table-responsive">
              <v-skeleton-loader
                type="table-thead"
                v-if="loading"
              >
              </v-skeleton-loader>

              <v-skeleton-loader
                v-if="loading"
                type="table-row-divider@25"
              >
              </v-skeleton-loader>

              <table class="table" v-if="! loading">
                <thead>
                  <tr class="px-3">
                    <th class="px-3">Syllabus</th>
                    <th class="px-3">Exams</th>
                    <th class="px-3">Category</th>
                    <th class="px-3">Family</th>
                    <th class="px-3">Grade</th>
                    <th class="px-3">SCORE</th>
                    <th class="px-3">Status</th>
                    <th class="pr-3 text-center">Options</th>
                  </tr>
                </thead>

                <template>

                    <tr v-for="(item, index) in syllabuses" :key="index">
                      <td class="px-3 font-weight-bolder">
                        <a @click="editSyllabus(item)"
                           class="text-primary font-weight-bolder text-hover-primary mb-1">
                          {{ item.name }}
                        </a>
                      </td>
                      <td class="px-3">
                        <span class="font-weight-bolder d-block font-size-lg">
                          {{ item.product_count }}
                        </span>
                      </td>
                      <td class="px-3">
                        <div class="">
                          {{ item.category_name? item.category_name:'NA' }}
                        </div>
                      </td>
                      <td class="px-3">
                        <div class="">
                          {{ item.instrument_name? item.instrument_name:'NA' }}
                        </div>
                      </td>

                      <td class="px-3">
                        <ul v-if="item.applicable_grade_lists">
                          <li class="font-weight-bolder text-dark-65" v-for="grade in item.applicable_grade_lists">
                            {{grade.name}}
                          </li>
                        </ul>
                        <span class="font-weight-bolder text-dark-65" v-else>
                          NA
                        </span>
                      </td>

                      <td class="px-3">
                        <ul v-if="item.score_lists">
                          <li class="font-weight-bolder text-dark-65" v-for="score in item.score_lists">
                            {{ score.name }}
                          </li>
                        </ul>
                        <span class="font-weight-bolder text-dark-65" v-else>
                          NA
                        </span>
                      </td>

                      <td class="px-3">
                        <span class="badge text-lg"
                              v-bind:class="{ 'badge-success': item.is_active, 'badge-danger': !item.is_active }"
                        >{{ item.is_active ? 'Active' : 'Inactive' }}</span>
                      </td>

                      <td class="pr-0 text-left">
                        <template>
                          <b-dropdown
                              size="sm"
                              variant="link"
                              toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                              no-caret
                              right
                              no-flip
                          >
                            <template v-slot:button-content>
                              <i class="ki ki-bold-more-hor"></i>
                            </template>
                            <div class="navi navi-hover min-w-md-250px">
                              <b-dropdown-text tag="div" class="navi-item"  v-if="currentUser.access_type=='federal'">
                                <a @click="editSyllabus(item)" class="navi-link">
                                  <span class="navi-icon">
                                    <i class="fas fa-edit"></i>
                                  </span>
                                  <span class="navi-text">Edit</span>
                                </a>
                              </b-dropdown-text>
                              <b-dropdown-text tag="div" class="navi-item">
                                <a class="navi-link" @click.prevent="deleteSyllabus(item.id)">
                                  <span class="navi-icon">
                                      <i class="fas fa-trash"></i>
                                  </span>
                                  <span class="navi-text">Delete</span>
                                </a>
                              </b-dropdown-text>
                            </div>
                          </b-dropdown>
                        </template>
                      </td>
                    </tr>
                    <tr v-if="syllabuses.length == 0">
                      <td class="text-center px-3" colspan="7">No items found.</td>
                    </tr>

                </template>
              </table>
              <b-pagination
                v-if="syllabuses.length > 0"
                class="pull-right mt-7"
                @input="getSyllabuses"
                v-model="page"
                :total-rows="total"
                :per-page="perPage"
                first-number
                :disabled="loading"
                last-number
              ></b-pagination>
            </div>
            <!-- table ends -->
            <v-overlay :value="isSorting">
              <v-progress-circular
                indeterminate
                color="dark"
              ></v-progress-circular>
            </v-overlay>
          </div>
        </div>
      </div>
      
      <create-and-update ref="create-and-update" @refresh="getSyllabuses"></create-and-update>

    </div>
  </v-app>    
</template>

<script>
import CreateAndUpdate from "./CreateAndUpdate";
import CategoryService from "@/services/product/category/CategoryService";
import InstrumentService from "@/services/product/instrument/InstrumentService";
import SyllabusService from "@/services/product/syllabus/SyllabusService";
import draggable from 'vuedraggable';

const category = new CategoryService();
const instrument = new InstrumentService();
const syllabus = new SyllabusService();

export default{
    components: {
        CreateAndUpdate, draggable
    },
    data(){
        return{
            loading: false,
            loading_instrument: false,
            isSorting: false,
            instrumentId: null,
            total: null,
            perPage: null,
            page: null,
            syllabuses: [],
            search:{
                name: '',
                status: '',
                category_id: '',
                instrument_id: '',
            },
            search_statuses: [
                {name: 'Active', value: 1},
                {name: 'Inactive', value: 0},
            ],
            search_categories:[],
            search_instruments:[],
            currentUser:{}
        }
    },
    methods:{
        searchSyllabuses(){
          this.getSyllabuses();
        },
        getSyllabuses(){
          this.loading = true;
            syllabus
            .paginate(this.search, this.page)
            .then(response => {
              this.syllabuses=response.data.data;
              this.page = response.data.meta.current_page;
              this.total = response.data.meta.total;
              this.perPage = response.data.meta.per_page;
              this.loading = false;
            })
            .catch((err) => {
              this.loading = false;
            });
        },
        sort(){
          this.isSorting = true;
          syllabus
          .sort(this.syllabuses)
          .then(response => {
            this.getSyllabuses();
            this.isSorting = false;
            this.$snotify.success('Syllabus Sorted!!');
          })
          .catch(err => {
            this.isSorting = false;
            this.$snotify.error("Oops something went wrong");
          });
        },
        createSyllabus(){
            this.$refs['create-and-update'].createSyllabus();
        },
        editSyllabus(item) {
            this.$refs['create-and-update'].editSyllabus(item);
        },
        deleteSyllabus(syllabusId){
          this.$confirm({
            message: `Are you sure? `,
            button: {
              no: "No",
              yes: "Yes",
            },
            callback: (confirm) => {
              if (confirm) {
                syllabus
                .delete(syllabusId)
                .then((response) => {
                  this.getSyllabuses();
                  this.$snotify.success("Syllabus deleted");
                })
                .catch((err) => {
                });
              }
            },
          });
        },
        getAllCategories() {
          category
          .all()
          .then(response => {
            this.search_categories = response.data.categories;
          })
          .catch((err) => {
          });
        },
        getAllInstruments() {
          instrument
          .all()
          .then(response => {
            this.search_instruments = response.data.instruments;
          })
          .catch((err) => {
          });
        },
        showInstrument(){
          this.loading_instrument = true;
          if(this.search.category_id){
            category
            .getInstruments(this.search.category_id)
            .then(response => {
              this.search_instruments = response.data.instruments;
              this.loading_instrument = false;
            })
            .catch((err) => {
              this.loading_instrument = false;
            });
          }else{
            this.search_instruments = [];
            this.search.category_id = null;
            this.search.instrument_id = null;
            this.loading_instrument = false;
          }
        },
      getCurrentUser() {
        this.currentUser =  this.$store.getters.currentUser;
      }
    },
    mounted(){
      this.instrumentId = this.$route.params.instrumentId;
      if(this.instrumentId != undefined && this.instrumentId != null) {
        this.search.instrument_id = this.instrumentId;
      }
      this.getSyllabuses();
      this.getAllCategories();
      this.getAllInstruments();
      this.getCurrentUser();
    }
}
</script>
