import Apiservice from '@/common/api.service';
import {API_URL} from '@/common/config.js'

const apiService = Apiservice;

export default class SettingService
{
    #api = null;

    constructor() {
        this.#api = API_URL + 'admin/setting';
    }
    paginate(data = {}, index = null) {
        let url = `${this.#api}`;
        if (index != null)
            url = `${url}?page=${index}`;
        let param = {
            params: data
        }
        return apiService.query(url, param);
    }

    create(data) {

        let url = `${this.#api}`;
        return apiService.post(url, data);

    }

    update(id, data) {
        let url = `${this.#api}/${id}`;
        return apiService.put(url, data)
    }

    show(id) {
        let url = `${this.#api}/${id}`
        return apiService.get(url)
    }

    delete(id) {
        let url = `${this.#api}/${id}`
        return apiService.delete(url);
    }
    findSettingData(data){
        let url = `${this.#api}/find-by-name`
        let param = {
            params: data
        }
        return apiService.query(url, param);
    }

    getAllYears(){
        let url = `${this.#api}/years`
        return apiService.get(url)
    }
    getAcceptedValueData(){
        let url = `${this.#api}/practical-result-pdf`
        return apiService.get(url)
    }
    getMarkedObtainedOption()
    {
        let url = `${this.#api}/mark-obtained-option`;
        return apiService.get(url)
    }
    getMarkedTypeOption()
    {
        let url = `${this.#api}/mark-type-option`;
        return apiService.get(url)
    }
    getPieceNamingOption()
    {
        let url = `${this.#api}/piece-name-option`;
        return apiService.get(url)
    }
    getMarkedTitleOption(data={})
    {
        let url = `${this.#api}/mark-title-option`;
        let param ={
            params: data
        }
        return apiService.query(url,param)
    }
    getRockSchoolDiplomaOption()
    {
        let url = `${this.#api}/rockshool-diploma-option`;
        return apiService.get(url)
    }
    getAdminUserTypeOption()
    {
        let url = `${this.#api}/admin-type`;
        return apiService.get(url)
    }
    getCurrencyData(){
        let url = `${this.#api}/currency`;
        return apiService.get(url);
    }
    getPaypalAccountType(){
        let url = `${this.#api}/paypal-account-type`;
        return apiService.get(url);
    }
    getAdditionalOptionTitleValue(){
        let url = `${this.#api}/additional_option_title_value`;
        return apiService.get(url);
    }
    getMarkOptions(data={}){
        let url = `${this.#api}/mark-options`;
        let param = {
            params: data
        }
        return apiService.query(url,param);
    }
    getRequestAppealPrice(){
        let url = `${this.#api}/appeal-price`;
        return apiService.get(url);
    }
    getPurchaseTypes(){
        let url = `${this.#api}/purchase_types`;
        return apiService.get(url);
    }
}