<template>
  <v-dialog
    v-model="dialog"
    scrollable
    max-width="700"
  >
    <v-card>
      <v-toolbar dark>
        <v-card-title>
          <span>{{ title }} syllabus</span>

            <hr>
        </v-card-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeDialog">
            <i class="fa fa-close"></i>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text>
        <v-container>
          <v-row class="mt-2">
            <v-col cols="12" md="12">
              <v-text-field
                outlined
                :error="$v.syllabus.name.$error"
                dense
                v-model="syllabus.name"
              >
                <template v-slot:label>
                  Syllabus title <span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="errors.name" >{{errors.name[0]}}</span>
              <span class="text-danger" v-if="$v.syllabus.name.$error">This information is required</span>
            </v-col>

            <v-col cols="12" md="12">
                <label> Description </label>
                <ckeditor
                  :error="$v.syllabus.description.$error"
                  :config="editorConfig"
                  v-model="syllabus.description" >
                </ckeditor>
                <span class="text-danger" v-if="errors.description" >{{errors.description[0]}}</span>
                <span class="text-danger" v-if="$v.syllabus.description.$error">This information is required</span>
            </v-col>

            <v-col cols="12" v-if="currentUser.access_type == 'federal'">
              <div class="text-body">SCORE</div>
              <v-select
                  item-text="name"
                  item-value="id"
                  :items="scores"
                  v-model="syllabus.score_ids"
                  multiple
                  small-chips
                  deletable-chips
                  outlined
                  dense
              >
                <template v-slot:prepend-item>
                  <v-list-item
                      ripple
                      @click="allSelectScores"
                  >
                    <v-list-item-action>
                      <v-icon :color="syllabus.score_ids > 0 ? 'indigo darken-4' : ''">
                        {{ iconScore }}
                      </v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        Select all
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="mt-1"></v-divider>
                </template>
              </v-select>
            </v-col>

            <v-col cols="12" sm="12" >
              <div class="text-body">Grades</div>
              <v-select item-text="name" item-value="id"
                  v-model="syllabus.applicable_grades" :items="grades" :error="$v.syllabus.applicable_grades.$error"
                  multiple small-chips deletable-chips outlined dense>
                <template v-slot:prepend-item>
                  <v-list-item ripple @click="allSelectGrades">
                    <v-list-item-action>
                      <v-icon :color="syllabus.applicable_grades > 0 ? 'indigo darken-4' : ''">
                        {{ iconGrade }}
                      </v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        Select all
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="mt-1"></v-divider>
                </template>
              </v-select>
              <span class="text-danger" v-if="errors.applicable_grades">{{ errors.applicable_grades[0] }}</span>
              <span class="text-danger" v-if="$v.syllabus.applicable_grades.$error">This information is required</span>
            </v-col>
            
<!--            <v-col cols="12" md="6">-->
<!--              <v-text-field-->
<!--                label="Certificate Print Name"-->
<!--                outlined-->
<!--                dense-->
<!--                :error="$v.syllabus.certificate_print_name.$error"-->
<!--                v-model="syllabus.certificate_print_name"-->
<!--              ></v-text-field>-->
<!--              <span class="text-danger" v-if="errors.certificate_print_name" >{{errors.certificate_print_name[0]}}</span>-->
<!--              <span class="text-danger" v-if="$v.syllabus.certificate_print_name.$error">This information is required</span>-->
<!--            </v-col>-->

            <v-col cols="12" sm="12" md="6">
              <v-select
                label="Category"
                v-model="syllabus.category_id"
                :items="categories"
                outlined
                @change="changeCategory"
                item-text="name"
                item-value="id"
                dense
              >
              </v-select>
              <span class="text-danger" v-if="errors.category_id">{{ errors.category_id[0] }}</span>
            </v-col>

            <v-col v-if="instrument_flag" cols="12" sm="12" md="6">
              <v-select
                  label="Instrument"
                  v-model="syllabus.instrument_id"
                  :items="instruments"
                  outlined @change="changeInstrument"
                  item-text="name"
                  :loading="loading_instrument"
                  item-value="id"
                  dense
              >
              </v-select>
              <span class="text-danger" v-if="errors.instrument_id">{{ errors.instrument_id[0] }}</span>
            </v-col>
          </v-row>
          <v-row>



            <v-col cols="12" sm="6" md="4" v-if="check_exam_type_enabled">
              Exam type
              <v-switch
                  :error="$v.syllabus.has_exam_types.$error"
                  v-model="syllabus.has_exam_types"
                  :label="syllabus.has_exam_types ? 'Yes':'No' "
              ></v-switch>
              <span class="text-danger" v-if="$v.syllabus.has_exam_types.$error">This information is required</span>
              <span class="text-danger" v-if="errors.has_exam_types">{{ errors.has_exam_types[0] }}</span>
            </v-col>

            <v-col cols="12" sm="6" md="4"  v-if="syllabus.has_exam_types && check_exam_option_enabled">
               Exam option
              <v-switch
                  v-model="syllabus.has_exam_options"
                  :label="syllabus.has_exam_options ? 'Yes' : 'No'"
                  :error="$v.syllabus.has_exam_options.$error"
              ></v-switch>
              <span class="text-danger" v-if="$v.syllabus.has_exam_options.$error">This information is required</span>
              <span class="text-danger" v-if="errors.has_exam_options">{{ errors.has_exam_options[0] }}</span>
            </v-col>

            <v-col cols="12" sm="12" md="8">
             Online Speech exam ?
              <v-switch
                  v-model="syllabus.is_online_exam_speech_syllabus"
                  :label="syllabus.is_online_exam_speech_syllabus ? 'Yes' : 'No'"
                  :error="$v.syllabus.is_online_exam_speech_syllabus.$error"
              ></v-switch>
              <span class="text-danger" v-if="$v.syllabus.is_online_exam_speech_syllabus.$error">This information is required</span>
              <span class="text-danger" v-if="errors.is_online_exam_speech_syllabus">{{ errors.is_online_exam_speech_syllabus[0] }}</span>
            </v-col>

            <v-col cols="12" sm="6" md="4">
              Status
              <v-switch :error="$v.syllabus.is_active.$error"
                        v-model="syllabus.is_active"
                        :label="syllabus.is_active ? 'Active': 'Inactive'"
                        color="primary"
              ></v-switch>
              <span class="text-danger" v-if="$v.syllabus.is_active.$error">This information is required</span>
              <span class="text-danger" v-if="errors.is_active">{{ errors.is_active[0] }}</span>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              Requires APRA declaration ?
              <v-switch v-model="syllabus.requires_apra_declaration"
                        :label="syllabus.requires_apra_declaration ? 'Yes': 'No'"
                        color="primary"
              ></v-switch>
            </v-col>

          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            x-large
            text
            @click="closeDialog">
          Cancel
        </v-btn>
        <v-btn
            x-large
            dark
            @click="createOrUpdate()"
            :loading="loading"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import CategoryService from "@/services/product/category/CategoryService";
import {required, requiredIf} from "vuelidate/lib/validators";
import GradeService from "@/services/product/grade/GradeService";
import InstrumentService from "@/services/product/instrument/InstrumentService";
import SyllabusService from "@/services/product/syllabus/SyllabusService";
import ScoreService from "@/services/score/score/ScoreService";
import SettingService from "@/services/setting/SettingService";

const setting=new SettingService();
const grade = new GradeService();
const category = new CategoryService();
const instrument = new InstrumentService();
const syllabus = new SyllabusService();
const score = new ScoreService();

export default {
  validations: {
    syllabus: {
      name:{required},
      description: {},
      has_exam_types: {required},
      has_exam_options: {required},
      applicable_grades:{required},
      score_ids: [],
      category_id: {},
      instrument_id: {},
      position: {},
      is_active: {required},
      exam_type: {},
      is_online_exam_speech_syllabus: {},
      has_exam_options_enabled:false,
    }
  },
  data() {
    return {
        title: "",
        edit: false,
        errors: [],
        dialog: false,
        loading_instrument: false,
        loading: false,
        grades: [],
        categories: [],
        instruments: [],
        scores: [],
        userTypes: [
          { name: "Federal", value: "federal" },
          { name: "Score", value: "score" },
          { name: "Both", value: "both" }
        ],
        currentUser: {},
        syllabus: {
          name: '',
          description: '',
          certificate_print_name: '',
          has_exam_types: 0,
          has_exam_options: 0,
          applicable_grades: [],
          category_id: null,
          instrument_id: null,
          position: null,
          is_active: 1,
          requires_apra_declaration: 0,
          is_online_exam_speech_syllabus: 0,
        },
      practical_result_report_options:[],
      practical_result_report_value:[],
      piece_naming_standard: [],
        editorConfig: {
            toolbar: [ [ 'Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'NumberedList', 'BulletedList' ] ],
            versionCheck: false
        },
        instrument_flag: false,
        mark_types:[],
        categoryDetail:null,
      instrumentDetail:null,
      check_exam_option_enabled:false,
      check_exam_type_enabled:false,
    }
  },
  methods: {
    getCurrentUser() {
      this.currentUser = this.$store.getters.currentUser;
    },
    closeDialog() {
      this.dialog = false;
      this.errors = [];
      this.resetForm();
    },
    openDialog() {
      this.$v.$reset();
      this.dialog = true;
      this.getAllGrades();
      this.getAllCategories();
      this.getAllScore();
      this.getCurrentUser();
    },
    createSyllabus() {
      this.resetForm();
      this.openDialog();
      this.edit = false;
      this.title = "Add";
    },
    editSyllabus(item) {
      this.openDialog();
      this.edit = true;
      this.syllabus = item;
      this.title = "Edit";
      if(item.applicable_grades == null) {
        this.syllabus.applicable_grades = []
      }
      if(item.score_ids == null) {
        this.syllabus.score_ids = []
      }
      if(item.practical_result_report_options!=null){
        this.practical_result_report_value=item.practical_result_report_options.split(',');
      }else{
        this.practical_result_report_value=[];
      }
     this.changeCategory();

    },
    createOrUpdate() {
      this.$v.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      }
      else {
        if(this.practical_result_report_value.length >0){
          this.syllabus.practical_result_report_options=this.practical_result_report_value.toString();
        }else{
          this.syllabus.practical_result_report_options='';
        }
        if (!this.edit) this._create();
        else this.update();
      }
    },
    allSelectScores () {
      this.$nextTick(() => {
        if (this.allSelectType) {
          this.syllabus.score_ids = []
        } else {
          this.syllabus.score_ids = [];
          this.scores.forEach(score => {
            this.syllabus.score_ids.push(score.id);
          });
        }
      })
    },
    allSelectGrades() {
      this.$nextTick(() => {
        if (this.allSelectGrade) {
          this.syllabus.applicable_grades = []
        } else {
          this.syllabus.applicable_grades = [];
          this.grades.forEach(grade => {
            this.syllabus.applicable_grades.push(grade.id);
          });
        }
      })
    },
    getAllScore() {
      score.all().then(response => {
        this.scores = response.data.scores;
      }).catch((err) => {
      });
    },
    getAllGrades() {
        grade
        .all()
        .then(response => {
        this.grades = response.data.grades;
        })
        .catch((err) => {
        });
    },
    //Category
    getAllCategories() {
      let data={};
      data={'has_syllabuses':1}
        category
        .getFilterData(data)
        .then(response => {
        this.categories = response.data.categories;
        })
        .catch((err) => {
        });
    },
    changeCategory(){
      this.getCategoryDetail();
    },
    getCategoryDetail(){
      category
          .show(this.syllabus.category_id)
          .then(response => {
            this.categoryDetail=response.data.category;
            if(this.categoryDetail && this.categoryDetail.has_instruments){
              this.instrument_flag=true;
              this.getInstruments();
              this.changeInstrument();
            }else{
              this.instrument_flag=false;
            }
            if(this.categoryDetail && this.categoryDetail.check_exam_options_enabled ){
              this.check_exam_option_enabled=true;
            }else{
              this.check_exam_option_enabled=false;
            }
            if(this.categoryDetail && this.categoryDetail.check_exam_type_enabled ){
              this.check_exam_type_enabled=true;
            }else{
              this.check_exam_type_enabled=false;
            }

          })
          .catch((err) => {

            this.$snotify.error("Oops something went wrong");
          });
    },
    //Instrument
    getInstruments() {
      let data={};
      if(this.syllabus.category_id){
        data['category_id']=this.syllabus.category_id;
      }
      data['has_syllabuses']=1;
      instrument
          .getFilterData(data)
          .then(response => {
            this.instruments=response.data.instruments
          })
          .catch((err) => {
          });
    },

    _create: function () {
      this.loading = true;
      syllabus
      .create(this.syllabus)
      .then(response => {
        this.$snotify.success("Syllabus added");
        this.resetForm();
        this.closeDialog();
        this.loading = false;
        this.$emit('refresh');
      })
      .catch((err) => {
        this.loading = false;
        this.errors = err.errors;
        this.$snotify.error("Oops something went wrong");
      });
    },
    update: function () {
      this.loading = true;
      syllabus
      .update(this.syllabus.id, this.syllabus)
      .then(response => {
        this.$snotify.success("Syllabus updated");
        this.resetForm();
        this.closeDialog();
        this.loading = false;
        this.$emit('refresh');
      })
      .catch((err) => {
        this.loading = false;
        this.errors = err.errors;
        this.$snotify.error("Oops something went wrong");
      });
    },
    resetForm() {
      this.syllabus = {
        name: '',
        description: '',
        certificate_print_name: '',
        has_exam_types: 0,
        has_exam_options: 0,
        applicable_grades: [],
        score_ids: [],
        category_id: null,
        instrument_id: null,
        position: null,
        is_active: 1,
        practical_result_report_options: [],
        mark_types: '',
        piece_naming_standard:'',
        general_remarks_naming_standard:'',
    },
      this.errors=[];
      this.$v.$reset();
    },

    getAcceptedValueData(){
      setting
          .getAcceptedValueData()
          .then(response => {
            for(let key in response.data.practical_result_report_options){
              this.practical_result_report_options.push({value:key, text:response.data.practical_result_report_options[key]})
            }
          })
    },
    getMarkedTypeOption(){
      setting
          .getMarkedTypeOption()
          .then(response => {

            for(let key in response.data.marked_type_options){

              this.mark_types.push({value:key, text:response.data.marked_type_options[key]})
            }
          })
    },
    allAcceptedValue() {
      this.$nextTick(() => {
        if (this.allSelectAcceptedValue) {
          this.practical_result_report_value = []
        } else {
          this.practical_result_report_value = [];
          this.practical_result_report_options.forEach(value => {
            this.practical_result_report_value.push(value);
          });
        }
      })
    },
    getPieceNamingOption(){
      setting
          .getPieceNamingOption()
          .then(response => {
            for(let key in response.data.piece_naming_options){
              this.piece_naming_standard.push({value:key, text:response.data.piece_naming_options[key]})
            }
          });
    },
    changeInstrument() {
      this.getInstrumentDetail();
    },
    getInstrumentDetail(){

      if(this.syllabus.instrument_id){
        instrument
            .show(this.syllabus.instrument_id)
            .then(response => {
              this.instrumentDetail=response.data.instrument;
              if(this.instrumentDetail && this.instrumentDetail.check_exam_options_enabled ){
                this.check_exam_option_enabled=true;
              }else{
                this.check_exam_option_enabled=false;
              }
              if(this.instrumentDetail && this.instrumentDetail.check_exam_type_enabled ){
                this.check_exam_type_enabled=true;
              }else{
                this.check_exam_type_enabled=false;
              }
            })
            .catch((err) => {
              this.$snotify.error("Oops something went wrong");
            });
      }

    },
  },
  mounted(){

    this.getAcceptedValueData();
    this.getMarkedTypeOption();
    this.getPieceNamingOption();
  },
  computed: {
    allSelectType () {
      return this.syllabus.score_ids.length === this.scores.length
    },
    selectType () {
      return this.syllabus.score_ids.length > 0 && !this.allSelectType
    },
    iconScore () {
      if (this.allSelectType) return 'mdi-close-box'
      if (this.selectType) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    },
    allSelectGrade () {
      return this.syllabus.applicable_grades.length === this.grades.length
    },
    selectGrade () {
      return this.syllabus.applicable_grades.length > 0 && !this.allSelectGrade
    },
    iconGrade () {
      if (this.allSelectGrade) return 'mdi-close-box'
      if (this.selectGrade) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    },
    allSelectAcceptedValue () {
      return this.practical_result_report_value.length === this.practical_result_report_options.length;
    },
    selectAcceptValue () {
      return this.practical_result_report_value.length > 0 && !this.allSelectAcceptedValue;
    },
    iconAcceptValue () {
      if (this.allSelectAcceptedValue) return 'mdi-close-box'
      if (this.selectAcceptValue) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    },
  }
}
</script>
